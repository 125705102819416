<template>
  <div>
    <van-cell-group title="基础信息">
      <van-cell title="单号" :value="model.SN" />
      <van-cell title="提交人" :value="model.SUB_NAME" />
      <van-cell title="状态" :value="model.STA_TXT" />
      <van-cell title="步骤" :value="model.STEP_DESC" v-if="model.STA == 10" />
      <van-cell title="日期">
        {{ model.CREATED_DT | datetime('YYYY-MM-DD') }}
      </van-cell>
      <van-cell title="归还日期">
        {{ model.FINISH_DT | datetime('YYYY-MM-DD') }}
      </van-cell>
      <van-cell title="标题" :value="model.TITLE" />
    </van-cell-group>
    <van-cell-group title="物品列表">
      <van-field v-for="(it, idx) in model.gridg" :key="idx" :label="it.ST_NAME" type="number" input-align="right"
        label-width="20em" v-model="it.CNT">
        <template #label>
          {{ it.ST_NAME }} 【{{ it.ST_UNIT }}】
        </template>
      </van-field>
    </van-cell-group>
    <van-cell-group title="物品损耗" v-if="model.STA == 15 && (model.STEP_TEA_ID == model.UID || model.STEP_TEA_IDS)">
      <van-field v-for="(it, idx) in model.grids" :key="idx" :label="it.ST_NAME" type="number" input-align="right"
        label-width="20em" v-model="it.CNT">
        <template #label>
          {{ it.ST_NAME }} 【{{ it.ST_UNIT }}】
        </template>
      </van-field>
    </van-cell-group>
    <van-cell-group title="审核记录">
      <van-cell v-for="(it, idx) in model.gridv" :key="idx">
        <template #title>
          {{ it.TEA_NAME }} ({{ it.STEP_NAME }})
        </template>
        <template #label>
          {{ it.VTIME | datetime('YYYY-MM-DD') }} |
          {{ it.DESC }}
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="我的意见" v-if="model.STA == 10 && (model.STEP_TEA_ID == model.UID || model.STEP_TEA_IDS)">
      <van-field type="text" v-model="model.DESC" placeholder="请输入您拒绝的理由" label="理由" />
      <van-row :gutter="10" style="padding: 10px 20px" :loading="ret.loading">
        <van-col :span="12">
          <van-button type="danger" icon="circle" block @click="refuse">拒绝</van-button>
        </van-col>
        <van-col :span="12">
          <van-button type="primary" icon="success" block @click="agree">同意</van-button>
        </van-col>
      </van-row>
    </van-cell-group>
    <div style="margin: 15px">
      <van-button v-if="model.STA == 10 && model.UID == model.SUB_ID" type="warning" size="large" icon="cross" block
        @click="cancel">撤消单据</van-button>
      <van-button v-if="model.STA == 15 && (model.STEP_TEA_ID == model.UID || model.STEP_TEA_IDS)" type="primary"
        size="large" icon="exchange" block @click="revert" style="margin-top: 15px">确认归还</van-button>
      <van-button plain type="default" size="large" icon="revoke" block @click="goback" style="margin-top: 15px">返
        回</van-button>
    </div>
    <van-overlay :show="ret.loading" style="text-align: center; padding-top: 300px;">
      <van-loading size="48" type="spinner" color="#fff" vertical>处理中,请稍候 。。。</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: "edit",
  data() {
    return {
      model: {
        gridg: [],
        gridv: [],
        grids: [],
      },
      ret: {
        loading: false
      }
    }
  },
  created() {
    let id = this.$route.query.ID;
    this.get(id);
  },
  methods: {
    get(id) {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/RENT/RDatApi/Get",
        data: { ID: id },
        completed: function (m) {
          //损耗数据
          m.DATA.grids = JSON.parse(m.DATA.GBODY);
          m.DATA.grids.forEach(item => {
            item.CNT = 0;
          })
          m.DATA.gridg = JSON.parse(m.DATA.GBODY);
          m.DATA.gridv = JSON.parse(m.DATA.VBODY);
          self.model = m.DATA;
          let user = self.whale.user.get();
          self.model.UID = user.ID;

        }
      })
    },
    cancel() {
      let self = this;
      Dialog.confirm({
        title: "撤消",
        message: "真的要撤消这张采购单吗？"
      }).then(() => {
        self.ret.loading = true;
        self.whale.remote.getResult({
          url: "/api/Mobile/RENT/RDatApi/Cancel",
          data: { ID: self.model.ID },
          finally() { self.ret.loadin = false; },
          completed: function () {
            self.opCompleted();
          }
        })
      }).catch(() => { })
    },
    refuse() {
      let self = this;
      self.ret.loading = true;
      this.whale.remote.getResult({
        url: "/api/Mobile/RENT/RDatApi/Refuse",
        data: {
          ID: self.model.ID,
          DESC: self.model.DESC
        },
        finally() { self.ret.loading = false; },
        completed: function () {
          self.opCompleted();
        }
      })
    },
    agree() {
      let self = this;
      self.ret.loading = true;
      this.whale.remote.getResult({
        url: "/api/Mobile/RENT/RDatApi/Agree",
        data: self.model,
        finally() { self.ret.loading = false; },
        completed: function () {
          self.opCompleted();
        }
      })
    },
    opCompleted() {
      let self = this;
      self.$dialog.alert({
        title: "完成",
        message: "记录已成功提交！",
        theme: "round",
      }).then(() => { self.goback(); })
    },
    goback() {
      let back = this.$route.query.back;
      if (back == "home") this.$router.push("/")
      else
        this.$router.go(-1);
    },
    revert() {
      let self = this;

      let verify = true;
      self.model.grids.forEach((item, index) => {
        if (self.model.gridg[index].CNT < item.CNT) {
          alert(item.ST_NAME + ":报废数量错误")
          verify = false;
          return false;
        }
      })
      console.log('verify', verify)
      if (verify) {
        self.ret.loading = true;
        this.whale.remote.getResult({
          url: "/api/Mobile/RENT/RDatApi/Revert",
          data: { ID: self.model.ID, bfinfo: self.model.grids },
          finally() { self.ret.loading = false; },
          completed: function () {
            self.opCompleted();
          }
        })
      }
    }
  }
}
</script>
<style scoped></style>
